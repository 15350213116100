<template>
  <div class="system">
    <div class="title">小程序、网页端、三维桌面一体机端LGOO</div>
    <el-upload
      class="upload-demo"
      action=""
      :headers="myHeaders"
      :before-upload="beforeUploadFile"
      accept=".jpg,.jpeg,.png,.JPG,.JPEG,"
      :show-file-list="false"
      multiple
      :limit="1"
      :file-list="fileList"
      name="file"
      :http-request="(file) => uploadURL(file)"
    >
      <el-button type="primary" style="margin-left: 15px"
        >点击上传logo</el-button
      >
    </el-upload>
    <div class="title">编辑器背景设置</div>
    <div class="img-list">
      <el-upload
        class="upload-demo"
        action=""
        :headers="myHeaders"
        accept=".jpg,.jpeg,.png,.JPG,.JPEG,"
        :show-file-list="false"
        multiple
        :limit="1"
        :file-list="fileList"
        name="file"
        :http-request="(file) => uploadURL(file, true)"
      >
        <div class="img-box upload">+</div>
      </el-upload>
      <div
        v-for="(item, index) of backgroundList"
        :key="index"
        class="img-box"
        :style="item.imageUrl | imgFormat(true)"
      >
        <i class="el-icon-circle-close" @click="deleteImg(item.id)"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ossData: {},
      fileList: [],
      backgroundList: [],
      upCount: 0,
    };
  },
  async mounted() {
    let ossData = await this.getOssKey();
    this.ossData = ossData.data;
    this.getBackgroundList();
  },
  computed: {
    myHeaders() {
      let token = localStorage.getItem("access_token");
      let value = {
        Authorization: token,
        uploadType: "avatar",
      };
      return value;
    },
  },
  methods: {
    deleteImg(id) {
      this.$confirm("确认删除背景?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$ajax
            .post("/systemSettings/background/delete", {
              id,
            })
            .then(() => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getBackgroundList();
            });
        })
        .catch(() => {});
    },
    beforeUploadFile(file) {
      let testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      let size = file.size / 1024 / 1024;

      if (!testmsg) {
        this.$message.warning({
          message: "错误格式提示 ： 图片格式不正确",
        });
        return false;
      }
      if (size > 2) {
        this.$message.warning({
          message: "图片不可超过2M",
        });
        return false;
      }
    },
    uploadURL(file, isBackground) {
      this.uploadOss(file, isBackground);
    },
    getOssKey() {
      return this.$ajax.post("user/oss/getKey?pathName=avatar");
    },
    uploadOss(file, isBackground) {
      var filename = this.ossData.fileName;
      var formData = new FormData(); //注意formData里append添加的键的大小写
      formData.append(
        "key",
        this.ossData.path + filename + file.file.type.replace("image/", ".")
      ); //存储在oss的文件路径
      formData.append("OSSAccessKeyId", this.ossData.accessid); //accessKeyId
      formData.append("policy", this.ossData.policy); //policy
      formData.append("Signature", this.ossData.signature); //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
      formData.append("file", file.file);
      formData.append("success_action_status", 200); //成功后返回的操作码

      var url = this.ossData.host;
      var fileUrl =
        this.ossData.path + filename + file.file.type.replace("image/", ".");
      this.$axios
        .post(url, formData)
        .then((res) => {
          this.upCount = 0;
          if (isBackground) {
            this.uploadBackground(fileUrl);
          } else {
            this.changeAvatar(fileUrl);
          }
        })
        .catch(async (error) => {
          this.upCount++;
          if (this.upCount >= 3) {
            return;
          }
          this.upCount++;
          let ossData = await this.getOssKey();
          this.ossData = ossData.data;
          this.uploadOss(file);
        });
    },
    uploadBackground(url) {
      this.$ajax
        .post("/systemSettings/background/save", {
          imageUrl: url,
          imageName: new Date().getTime() + "",
        })
        .then((res) => {
          this.$message.success("图片上传成功");
          this.getBackgroundList();
        });
    },
    getBackgroundList() {
      this.$ajax.post("/systemSettings/background/query").then((res) => {
        this.backgroundList = res.data;
      });
    },
    changeAvatar(logo) {
      this.$ajax
        .post("/systemSettings/institution/logo/change", {
          logo,
        })
        .then(() => {
          this.$message.success("logo上传成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.system {
  padding: 30px 10px;
}
.title {
  font-size: 30px;
  padding: 20px 0 30px 0;
}

.upload {
  width: 200px;
  height: 150px;
  font-size: 50px;
  line-height: 140px;
}

.img-list {
  display: flex;
  flex-wrap: wrap;

  > div {
    display: block;
    width: 200px;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ccc;
    position: relative;
    margin: 15px;
  }
}
.el-icon-circle-close {
  position: absolute;
  font-size: 20px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
</style>